/* eslint-env worker */
/* globals OffscreenCanvas ebml */

/**
 * An object used to keep track of canvases on the page
 * @type {Object}
 */
let canvases = {}

/**
 * The pid of the interval function
 * @type {Number}
 */
let intervalPid = null

/**
 * How many pixels the canvas needs to shift to the left
 * @type {Number}
 */
const pxToTravel = 1

// we want to read one every 4 audio data points
let divider = 4

this.onmessage = async function (e) {
  let canvasId
  let canvasObject

  switch (e.data.command) {
    case 'add-canvas':
      this.addCanvas(e.data)
      break
    case 'audio':
      const width = e.data.width
      canvasId = e.data.canvasId
      // even if isRecording is missing, we are showing the waves
      const isRecording = !!e.data.isRecording

      canvasObject = canvases[canvasId]

      let audio

      if (!canvasId) {
        self.postMessage({
          error: 'Missing argument canvasId',
          canvasId: canvasId
        })
        return
      }

      if (typeof canvasObject !== 'object') {
        self.postMessage({
          error: 'Received audio data for a canvas that we are not tracking ' + canvasId,
          canvasId: canvasId
        })
        return
      }

      // check every time that the canvas is the right size
      this.resize(canvasObject, width)

      if (e.data.buffer) {
        audio = new Float32Array(e.data.buffer)
        this.draw(canvasObject, audio, isRecording)
      } else if (e.data.blob) {
        this.extractAudioFromBlob(e.data.blob, canvasObject).then((audio) => {
          this.draw(canvasObject, audio, isRecording)
        })
      } else {
        self.postMessage({
          error: 'No audio received',
          canvasId: canvasId
        })
      }
      break
    case 'remove':
      delete canvases[e.data.canvasId]
      break
    case 'reset':
      canvasId = e.data.canvasId
      canvasObject = canvases[canvasId]

      if (canvasObject) {
        canvasObject.demuxer = new ebml.EbmlDecoder()
      }

      break
  }
}

/**
 * Used to add a new canvas that will be updated by this worked
 * @param {[type]} data [description]
 */
this.addCanvas = function (data) {
  let canvasId = data.canvasId

  if (!canvasId) {
    self.postMessage({
      error: 'Missing argument canvasId',
      canvasId: canvasId
    })
    return
  }

  if (canvasId in canvases) {
    self.postMessage({
      error: 'canvasId already added',
      canvasId: canvasId
    })
    return
  }

  if (!this.ebml) {
    importScripts(data.decoderUrl)
  }

  let canvasObject = {
    canvasId: canvasId,
    drawX: 0,
    canvasClearedForRecording: false
  }

  if (!data.pullMethod) {
    canvasObject.demuxer = new ebml.EbmlDecoder()
    canvasObject.floatBuffer = new Float32Array(6 * 480 / divider) // 960
  }

  canvasObject.wavCanvas = data.canvas
  canvasObject.wctx = canvasObject.wavCanvas.getContext('2d', {alpha: false})

  canvasObject.bufCanvas = new OffscreenCanvas(canvasObject.wavCanvas.width, canvasObject.wavCanvas.height)
  canvasObject.bctx = canvasObject.bufCanvas.getContext('2d', {alpha: false})

  canvasObject.bufCanvas.width = canvasObject.wavCanvas.width
  canvasObject.bufCanvas.height = canvasObject.wavCanvas.height

  canvasObject.wctx.fillRect(0, 0, canvasObject.wavCanvas.width, canvasObject.wavCanvas.height)
  canvasObject.bctx.fillRect(0, 0, canvasObject.bufCanvas.width, canvasObject.bufCanvas.height)

  this.applyCanvasStyles(canvasObject.wctx)
  this.applyCanvasStyles(canvasObject.bctx)

  // clear the canvas at the beginning
  canvasObject.wctx.fillRect(0, 0, canvasObject.wavCanvas.width, canvasObject.wavCanvas.height)

  canvases[canvasId] = canvasObject

  if (data.pullMethod) {
    this.startPoll(canvasObject.canvasId)
  }
}

this.resize = function (canvasObject, width) {
  // if the width hasn't changed, we don't need to update anything
  if (canvasObject.wavCanvas.width === width) {
    return
  }

  // bounce the image over to the buffer so we don't loose it when we resize the wavCanvas
  canvasObject.bctx.fillRect(0, 0, canvasObject.bufCanvas.width, canvasObject.bufCanvas.height)
  canvasObject.wavCanvas.width = width
  try {
    canvasObject.bctx.drawImage(canvasObject.wavCanvas, 0, 0)
  } catch (e) {}

  // resizing the canvas seems to clear out the styles so reapply
  this.applyCanvasStyles(canvasObject.wctx)
  canvasObject.wctx.fillRect(0, 0, canvasObject.wavCanvas.width, canvasObject.wavCanvas.height)
  canvasObject.wctx.drawImage(canvasObject.bufCanvas, canvasObject.bufCanvas.width - canvasObject.bufCanvas.width, 0)
  canvasObject.bufCanvas.width = canvasObject.wavCanvas.width
  this.applyCanvasStyles(canvasObject.bctx)
}

this.startPoll = function (canvasId) {
  if (intervalPid) return

  intervalPid = setInterval(async () => {
    // loop through the canvases and pull data for each
    for (let canvasId in canvases) {
      let canvasObject = canvases[canvasId]

      let {audio, width, isRecording} = await this.pullData(canvasObject.canvasId)
      this.resize(canvasObject, width)

      this.draw(canvasObject, audio, isRecording)
    }
  }, 60)
}

this.pullData = function (canvasId) {
  return new Promise((resolve) => {
    const messageChannel = new MessageChannel()
    messageChannel.port1.onmessage = (event) => {
      resolve(event.data)
    }
    this.postMessage({command: 'pull', canvasId}, [messageChannel.port2])
  })
}

this.extractAudioFromBlob = function (data, canvasObject) {
  let j = 1
  let floatBuffer = canvasObject.floatBuffer

  // if the canvas is wider than the buffer, resize the buffer to display more
  if (canvasObject.wavCanvas.width > floatBuffer.length) {
    floatBuffer = new Float32Array(canvasObject.wavCanvas.width)
  }

  return new Promise(async (resolve, reject) => {
    let arrayBuffer

    if (data instanceof Blob) {
      arrayBuffer = await data.arrayBuffer()
    } else if (data instanceof ArrayBuffer) {
      arrayBuffer = data
    } else {
      return reject(new Error('Invalid data format'))
    }

    canvasObject.demuxer.write(arrayBuffer, function (elem) {
      if (elem[0] === 'tag' && elem[1].name === 'SimpleBlock') {
        let payload = elem[1].payload

        // first chunk is large (eg. 29229), ignore it
        if (payload.length > 4000) {
          resolve(new Float32Array([0]))
          return
        }

        let view = new DataView(payload.buffer)

        let len = Math.floor(payload.length / Float32Array.BYTES_PER_ELEMENT)

        let sum = 0
        let i = 0
        for (i; i < len - Float32Array.BYTES_PER_ELEMENT; i++) {
          sum += view.getFloat32(payload.byteOffset + i * Float32Array.BYTES_PER_ELEMENT, true)

          if (j * i % divider === 0) {
            floatBuffer[j * i / divider] = sum / divider
            sum = 0
          }
        }

        if (j === 6) {
          if (j * i / divider < canvasObject.wavCanvas.width) {
            divider--
            divider = Math.max(divider, 1)
          }
          resolve(floatBuffer)
        } else {
          j++
        }
      }
    })
  })
}

this.draw = function (canvasObject, audio, isRecording) {
  if (isRecording) {
    // when starting the recording, clear the canvas
    if (!canvasObject.canvasClearedForRecording) {
      canvasObject.wctx.fillRect(0, 0, canvasObject.wavCanvas.width, canvasObject.wavCanvas.height)
      canvasObject.canvasClearedForRecording = true
    }

    this.drawScrollingWaveform(canvasObject, audio)
  } else {
    this.drawWaveform(canvasObject, audio)
  }
}

this.applyCanvasStyles = function (ctx) {
  ctx.fillStyle = '#262626' // BW_15
  ctx.strokeStyle = '#666666' // BW_40
  ctx.lineWidth = 1
  ctx.lineCap = 'round'
}

this.drawWaveform = function (canvasObject, f32) {
  let x = 0

  let wctx = canvasObject.wctx
  let canvas = canvasObject.wavCanvas
  let height = canvas.height
  let width = Math.max(canvas.width, 1) // min width is 1

  let pxPerF32 = width / f32.length
  let pxInc = Math.max(Math.ceil(pxPerF32), 1)

  // clear the canvas
  canvasObject.wctx.fillRect(0, 0, width, height)

  wctx.beginPath()
  wctx.moveTo(x, height / 2)
  for (let i = 0; i < f32.length; i++) {
    let yOffset = f32[i] * height
    let y = height / 2 + yOffset / 2
    if (i === 0) wctx.moveTo(x, y)
    wctx.lineTo(x, y)
    x += pxInc
    if (x >= width) break // break out early if we are past the edge of the canvas
  }
  wctx.stroke()
}

this.drawScrollingWaveform = function (canvasObject, f32) {
  let self = canvasObject

  let wctx = self.wctx
  let height = self.wavCanvas.height

  // start shifting the canvas when we reach the end
  if (canvasObject.drawX >= self.wavCanvas.width - pxToTravel) {
    canvasObject.drawX = self.wavCanvas.width - pxToTravel

    // check to see if the canvas aren't the same size
    // and make bufCanvas match wavCanvas.
    if (self.bufCanvas.width !== self.wavCanvas.width) {
      self.bufCanvas.width = self.wavCanvas.width
      self.applyCanvasStyles(self.bctx)
    }

    self.bctx.fillRect(0, 0, self.bufCanvas.width, self.bufCanvas.height)
    self.bctx.drawImage(self.wavCanvas, -pxToTravel, 0)
    self.wctx.drawImage(self.bufCanvas, 0, 0)
  }

  let sample = Math.max.apply(Math, f32)

  self.lineHeight = self.lineHeight || 0

  let lineHeight = sample ? Math.max(Math.round(sample * height), self.wctx.lineWidth) : self.wctx.lineWidth

  self.lineHeight = lineHeight // no smoothing

  let x = canvasObject.drawX
  let y = (height / 2) - (self.lineHeight / 2)
  let y1 = y + self.lineHeight
  wctx.beginPath()
  wctx.moveTo(x, y)
  wctx.lineTo(x, y1)
  wctx.stroke()

  if (canvasObject.drawX < self.wavCanvas.width - canvasObject.wctx.lineWidth) {
    canvasObject.drawX += canvasObject.wctx.lineWidth
  }
}
